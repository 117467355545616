<template>
    <div class="ucenter">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('佣金记录')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="tabs-wrapper">
            <div class="com-flex flex-center">
                <div class="col">
                    <div class="label">{{$t('今天')}}</div>
                    <div class="val">{{ parseFloat(stat.today).toFixed(6) }}</div>
                </div>
                <div class="col">
                    <div class="label">{{$t("本周")}}</div>
                    <div class="val">{{ parseFloat(stat.week).toFixed(6) }}</div>
                </div>
                <div class="col">
                    <div class="label">{{$t("全部")}}</div>
                    <div class="val">{{ parseFloat(stat.total).toFixed(6) }}</div>
                </div>
            </div>
            <div class="proxy-list">
                <div class="proxy-row" v-for="item in list" :key="item">
                    <!-- <img src="../../assets/pics/team/dui.png" class="cicon" alt=""> -->
                    <div class="flex-bd">
                        <div class="proxy-tit">{{ item.title }}</div>
                    </div>
                    <div class="proxy-time">{{ item.created_at }}</div>
                    <div class="text" v-if="item.money > 0">+{{ parseFloat(item.money).toFixed(4) }}</div>
                    <div class="text" v-else>{{ parseFloat(item.money).toFixed(4) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                show: false,
                name: '',
                list: [],
                agent_list: [],
                child_list: [],
                stat: {
                    today: 0,
                    week: 0,
                    total: 0,
                },
                loading: false,
                finished: false,

            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
			getData() {
				let url = this.$api_url.distributions;
				this.$axios
						.get(url)
						.then((res) => {
							if (res.data.code == 0) {
								this.count = res.data.data.count;
								this.list = res.data.data.list;
								this.agent_list = res.data.data.agent_list;
								this.child_list = res.data.data.child_list;
								this.stat = res.data.data.stat;
							}
						})
						.catch((err) => {
							this.$toast.fail(err.message);
						});
			},
        },
		mounted() {
			this.getData();
			this.getUser().then((res) => {
				this.user = res.data;
				console.log(this.user);
			});
		},
    }
</script>
<style scoped="scoped">
    .van-tab--active .tm-tab-title {
        color: #FFFFFF;
    }
</style>
